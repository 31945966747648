import { SearchIcon, PlusIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { ITableHeader, Table } from '../../../components/table/Table';
import {
  GetPaymentRemindersDocument,
  GetPaymentRemindersQuery,
  GetPaymentRemindersQueryVariables,
} from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { formatNumber } from '../../../utils/number';
import { formatDate } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { LinkButton } from '../../../components/button/ButtonLink';

const PAYMENT_REMINDER_HEADERS: ITableHeader[] = [
  {
    id: 'customer',
    name: 'Klant',
  },
  {
    id: 'invoiceNumbers',
    name: 'Factuur Nummer(s)',
  },
  {
    id: 'reminderType',
    name: 'Type',
  },
  {
    id: 'total',
    name: 'Totaal',
  },
  {
    id: 'date',
    name: 'Datum',
  },
];

export const PaymentRemindersPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetPaymentRemindersQuery, GetPaymentRemindersQueryVariables>(
          GetPaymentRemindersDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.paymentReminders ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 100,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  const title = 'Betaalherinneringen';
  return (
    <>
      <PageHeader title={title} />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: 'Financieel',
                to: '/internal/finance',
              },
              {
                name: title,
              },
            ]}
          />

          <div className="flex gap-4">
            <div className="flex w-96">
              <Input
                type="text"
                placeholder="Zoek een betaalherinnering..."
                value={searchValue}
                onChange={setSearchValue}
                iconLeft={<SearchIcon className="input-icon" />}
              />
            </div>
            <div>
              <LinkButton to="new" color="primary" iconLeft={<PlusIcon className="button-icon" />}>
                Nieuwe herinnering
              </LinkButton>
            </div>
          </div>
        </div>

        <Table
          idKey="id"
          headers={PAYMENT_REMINDER_HEADERS}
          data={page.data}
          mapData={(paymentReminder) => {
            return [
              <Link to={`${paymentReminder.id}`} className="link-text">
                {paymentReminder.customer.name}
              </Link>,
              paymentReminder.invoices.map((invoice) => invoice.invoice.invoiceNumber).join(', '),
              paymentReminder.reminderType,
              `€ ${formatNumber(
                paymentReminder.invoices.reduce((acc, i) => acc + i.amount, 0),
                2,
                {
                  decimalSeperator: ',',
                },
              )}`,
              formatDate(paymentReminder.createdAt),
            ];
          }}
        />

        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};
