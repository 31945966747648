import { useParams } from 'react-router-dom';
import { CalendarDaysIcon, ClockIcon, HashIcon } from 'lucide-react';

import {
  OrderLineStopType,
  OrderStatus,
  useGetCustomerPortalOrderCmrLinkQuery,
  useGetCustomerPortalOrderQuery,
  useGetCustomerPortalOrderSustainabilityReportLinkQuery,
} from '../../../generated/graphql';
import { nullthrows } from '../../../utils/invariant';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { OrderStatusTag } from '../../order/components/OrderStatusTag';
import { DataField } from '../../../components/DataField';
import { formatDate, formatDateTime, formatInputTime, sortDate } from '../../../utils/date';
import { useTranslation } from '../../../contexts/translation-context';
import { formatNumber } from '../../../utils/number';
import { calculateLinesTotalExclVat } from '../../order/utils/price';
import { DownloadButton } from '../../document/components/DownloadButton';
import { OrderComplaintDialog } from './CustomerPortalOrderComplaintDialog';
import { TrailerTypeIcon } from '../../order/pages/order/TrailerTypes';

export const CustomerPortalOrderPage = () => {
  const { i18n } = useTranslation();
  const { orderId: _orderId } = useParams<{ orderId: string }>();
  const orderId = nullthrows(_orderId, 'Order ID is required');
  const [{ data }] = useGetCustomerPortalOrderQuery({
    variables: {
      id: orderId,
    },
  });
  const [{ data: cmrLinkData }] = useGetCustomerPortalOrderCmrLinkQuery({
    variables: {
      id: orderId,
    },
  });
  const [{ data: sustainabilityReportData }] = useGetCustomerPortalOrderSustainabilityReportLinkQuery({
    variables: {
      id: orderId,
    },
  });

  const sustainabilityReportLink = sustainabilityReportData?.customerPortalOrderSustainabilityReportLink;
  const cmrLink = cmrLinkData?.customerPortalOrderCmrLink;
  const order = nullthrows(data?.customerPortalOrder, 'Order not found');
  const executionDate = order.lines
    .map((l) => {
      return l.stops.filter((s) => s.type === OrderLineStopType.Load).sort((a, b) => sortDate(a.date, b.date))[0]?.date;
    })
    .sort(sortDate)[0];
  const distance = Math.round(order.lines.reduce((acc, curr) => curr.distance + acc, 0) / 1000);
  const emissions = order.lines.reduce((acc, curr) => curr.emissions + acc, 0);

  const totalWeight = order.lines.reduce(
    (acc, curr) => curr.contents.reduce((acc, curr) => acc + curr.weight, 0) + acc,
    0,
  );
  return (
    <div>
      <PageHeader title={order.orderNumber ?? 'DRAFT'} />

      <div className="page-heading">
        <div className="flex items-center gap-4">
          <Breadcrumb
            items={[
              {
                name: i18n('customerPortal.orders.orders'),
                to: '..',
              },
              {
                name: order.orderNumber ?? 'DRAFT',
              },
            ]}
          />

          <div>
            <OrderStatusTag status={order.status} />
          </div>
        </div>

        <div className="flex gap-2">
          <OrderComplaintDialog
            orderId={order.id}
            isDisabled={order.status === OrderStatus.Draft || order.status === OrderStatus.Cancelled}
          />
          <DownloadButton downloadLink={cmrLink} color="primary">
            CMR
          </DownloadButton>
        </div>
      </div>

      <div className="flex justify-between gap-8 mb-4">
        <div className="flex-1 flex flex-wrap gap-6">
          <DataField title={i18n('customerPortal.orders.invoiceRef')}>{order.customerRef}</DataField>
          <DataField title={i18n('customerPortal.orders.creationDate')}>{formatDate(order.createdAt)}</DataField>
          {executionDate && (
            <DataField title={i18n('customerPortal.orders.executionDate')}>{formatDate(executionDate)}</DataField>
          )}
          {order.creator && (
            <DataField title={i18n('customerPortal.orders.fileManager')}>{order.creator.name}</DataField>
          )}
          {order.cancellationReason && (
            <DataField title={i18n('customerPortal.orders.cancellationReason')}>{order.cancellationReason}</DataField>
          )}
        </div>
      </div>

      <div className="flex flex-wrap gap-2 my-2">
        {order.allowedTrailerTypes.map((trailerType) => {
          return (
            <div
              className="bg-dark-05 rounded flex flex-col items-center justify-center px-2 py-1"
              key={`${order.id}-${trailerType}`}
            >
              <TrailerTypeIcon type={trailerType} variant="small" />
            </div>
          );
        })}
      </div>

      <div className="grid gap-4 my-8">
        {order.lines.map((l) => {
          const saleTotal = calculateLinesTotalExclVat(l.sales);

          return (
            <div className="card" key={l.id}>
              <div className="heading-three mb-2">{i18n('customerPortal.orders.stops')}</div>
              <div className="grid grid-cols-2 gap-4">
                {l.stops.map((stop) => {
                  return (
                    <div key={stop.id}>
                      <div className="font-medium mb-2">
                        {stop.type === OrderLineStopType.Load
                          ? i18n('pages.orderSummary.load')
                          : i18n('pages.orderSummary.unload')}
                      </div>
                      <div className="mb-1">
                        <div>{stop.location.name}</div>
                        <div className="whitespace-nowrap">{`${stop.location.street} ${stop.location.streetNumber}`}</div>
                        <div>{`${stop.location.country}-${stop.location.postalCode}`}</div>
                      </div>
                      <div className="flex items-center">
                        <HashIcon className="w-4 h-4 mr-2" />
                        <div>{stop.reference || '-'}</div>
                      </div>
                      <div className="flex items-center">
                        <CalendarDaysIcon className="w-4 h-4 mr-2" />
                        <div>{formatDate(stop.date)}</div>
                      </div>
                      <div className="flex items-center">
                        <ClockIcon className="w-4 h-4 mr-2" />
                        <div className="whitespace-nowrap">{`${formatInputTime(stop.timeStart)} - ${formatInputTime(
                          stop.timeEnd,
                        )}`}</div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {!!l.sales.length && (
                <>
                  <div className="heading-three mt-4 mb-2">{i18n('customerPortal.orders.purchases')}</div>
                  <div>
                    {l.sales.map((sale) => {
                      return (
                        <div key={sale.id} className="grid grid-cols-4">
                          <div className="col-span-3 whitespace-nowrap">
                            {`${formatNumber(sale.amount, 2)} x ${sale.productType.nameNl}`}
                          </div>
                          <div className="whitespace-nowrap">
                            {`€${formatNumber((sale.amount * sale.unitPrice) / 100, 2, {
                              decimalSeperator: ',',
                            })}`}
                          </div>
                        </div>
                      );
                    })}

                    <div className="grid grid-cols-4 mb-2 border-t">
                      <div className="col-span-3 whitespace-nowrap font-medium">
                        {i18n('customerPortal.orders.total')}
                      </div>
                      <div className="whitespace-nowrap">
                        {`€${formatNumber(saleTotal, 2, {
                          decimalSeperator: ',',
                        })}`}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>

      {emissions > 0 && (
        <div className="card  my-8">
          <div className="flex justify-between items-center mb-2">
            <div className="heading-three">{i18n('customerPortal.orders.sustainability')}</div>

            <DownloadButton downloadLink={sustainabilityReportLink} color="primary">
              Report
            </DownloadButton>
          </div>
          <div className="grid grid-cols-4 gap-2">
            <DataField title={i18n('customerPortal.orders.truck')}>Euro 6</DataField>
            <DataField title={i18n('customerPortal.orders.distance')}>{`${distance}km`}</DataField>
            <DataField title={i18n('customerPortal.orders.weight')}>{`${totalWeight / 100}kg`}</DataField>
            <DataField title={i18n('customerPortal.orders.emissions')}>{`${formatNumber(emissions, 3, {
              decimalSeperator: ',',
            })}kg co2eq`}</DataField>
          </div>
        </div>
      )}

      {order.complaints.length > 0 && (
        <div>
          <div className="heading-two mb-4">{i18n('customerPortal.orders.complaints')}</div>

          <div className="grid gap-2">
            {order.complaints.map((v) => {
              return (
                <div className="card" key={v.id}>
                  <div>{v.content}</div>

                  <div className="flex justify-between items-center mt-2">
                    <div className="text-sm text-dark-02">{formatDateTime(v.createdAt)}</div>
                    <div className="px-2 py-1 rounded-md inline-flex items-center bg-dark-04 text-xs">
                      {i18n(`orderComplaintType.${v.type}`)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
