import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import CreateCustomerPage from './app/customer/pages/Customer/CreateCustomer';
import CustomerPage from './app/customer/pages/Customer';
import { UpdateCustomerForm } from './app/customer/pages/Customer/UpdateCustomerForm';
import { CustomerContacts } from './app/customer/pages/CustomerContact/Contacts';
import CreateCustomerContactPage from './app/customer/pages/CustomerContact/CreateContact';
import UpdateCustomerContactPage from './app/customer/pages/CustomerContact/UpdateContact';
import CustomersPage from './app/customer/pages/Customers';
import { StatisticsPage } from './app/stats/pages/StatisticsPage';
import CreateInvoicePage from './app/sale/pages/sale/create';
import InvoicePage from './app/sale/pages/sale/details';
import { SalesInvoicesPage } from './app/sale/pages/Sales';
import LocationPage from './app/location/pages/Location';
import CreateLocationPage from './app/location/pages/Location/Create';
import { UpdateLocationForm } from './app/location/pages/Location/update';
import { LocationContacts } from './app/location/pages/LocationContact/Contacts';
import CreateLocationContactPage from './app/location/pages/LocationContact/CreateContact';
import UpdateLocationContactPage from './app/location/pages/LocationContact/UpdateContact';
import LocationsPage from './app/location/pages/Locations';
import LocationOpeningHours from './app/location/pages/OpeningHours';
import OrderConfirmationPage from './app/order/pages/confirmOrder/ConfirmOrder';
import OrderRefusalPage from './app/order/pages/confirmOrder/RefuseOrder';
import CreateOrderPage from './app/order/pages/order/Create';
import OrderPage from './app/order/pages/order/Order';
import { UpdateOrder } from './app/order/pages/order/UpdateOrder';
import CreateOrderLinePage from './app/order/pages/orderLine/CreateOrderLine';
import OrderLineDetails from './app/order/pages/orderLine/OrderLine';
import OrderLinesPage from './app/order/pages/orderLine/OrderLines';
import CreateOrderLineContentPage from './app/order/pages/orderLineContent/CreateOrderLineContent';
import OrderLineContentsPage from './app/order/pages/orderLineContent/OrderLineContents';
import { UpdateOrderLineContentPage } from './app/order/pages/orderLineContent/UpdateOrderLineContent';
import { CreateOrderLineSalePage } from './app/order/pages/orderLineSale/CreateOrderLineSale';
import { OrderLineSalesPage } from './app/order/pages/orderLineSale/OrderLineSales';
import { UpdateOrderLineSalePage } from './app/order/pages/orderLineSale/UpdateOrderLineSale';
import { CreateOrderPurchasePage } from './app/order/pages/orderPurchase/CreateOrderPurchase';
import { OrderPurchasesPage } from './app/order/pages/orderPurchase/OrderPurchases';
import { UpdateOrderPurchasePage } from './app/order/pages/orderPurchase/UpdateOrderPurchase';
import OrdersPage from './app/order/pages/Orders';
import SettingsPage from './app/settings/pages';
import EmailSettingsPage from './app/settings/pages/email/email';
import EmailTypeSettingsPage from './app/settings/pages/email/email-type';
import GeneralSettingsPage from './app/settings/pages/general';
import InvoiceSettingsPage from './app/settings/pages/invoice';
import OrderSettingsPage from './app/settings/pages/order';
import { GeneralActionsPage } from './app/settings/pages/Actions';
import SupplierPage from './app/supplier/pages/Supplier';
import CreateSupplierPage from './app/supplier/pages/Supplier/CreateSupplier';
import { UpdateSupplierForm } from './app/supplier/pages/Supplier/UpdateSupplierForm';
import { SupplierContacts } from './app/supplier/pages/SupplierContact/Contacts';
import CreateSupplierContactPage from './app/supplier/pages/SupplierContact/CreateContact';
import UpdateSupplierContactPage from './app/supplier/pages/SupplierContact/UpdateContact';
import SuppliersPage from './app/supplier/pages/Suppliers';
import CreateUserPage from './app/user/pages/CreateUser';
import { MePage } from './app/user/pages/Me';
import UserPage from './app/user/pages/User';
import UsersPage from './app/user/pages/Users';
import { Dashboard } from './components/page-templates/Dashboard';
import { AuthConsumer, AuthProvider, useAuth } from './contexts/auth-context';
import { MinimalGeneralSettingsProvider } from './contexts/minimal-settings-context';
import CreditNotesPage from './app/creditnote/pages/CreditNotes';
import CreateCreditNotePage from './app/creditnote/pages/CreditNote/create';
import CreditNotePage from './app/creditnote/pages/CreditNote/details';
import PurchasesPage from './app/purchase/pages/Purchases';
import CreatePurchasePage from './app/purchase/pages/purchase/create/create';
import PurchasePage from './app/purchase/pages/purchase/details';
import { NotificationsProvider } from './app/notification/pages/context';
import NotificationsPage from './app/notification/pages/Notifications';
import { WebsocketProvider } from './contexts/websocket-context';
import DocumentNoteSettingsPage from './app/settings/pages/document/doc';
import DocumentNoteTypeSettingsPage from './app/settings/pages/document/doc-type';
import IncomingDocsPage from './app/incoming-document/pages/IncomingDocs';
import IncomingDocPage from './app/incoming-document/pages/IncomingDoc';
import SelfBillingsPage from './app/self-billing/pages/SelfBills';
import SelfBillPage from './app/self-billing/pages/SelfBill';
import IncomingOrdersPage from './app/order/pages/incomingOrder/IncomingOrders';
import IncomingOrderPage from './app/order/pages/incomingOrder/IncomingOrder';
import { OrdersOverviewStats } from './app/stats/pages/OrdersOverview';
import { AccountingStats } from './app/stats/pages/AccountingStats';
import { AuditLogsPage } from './app/audit-log/pages/AuditLogs';
import { MailboxesSettingsPage } from './app/settings/pages/mailbox/Mailboxes';
import { ConfigureMailboxPage } from './app/settings/pages/mailbox/ConfigureMailbox';
import { UpdateMailboxPage } from './app/settings/pages/mailbox/UpdateMailbox';
import QuotationsPage from './app/quotation/pages/Quotations';
import NewQuotationPage from './app/quotation/pages/NewQuotation';
import { QuotationPage } from './app/quotation/pages/Quotation';
import { FinancePage } from './app/finance/pages/FinancePage';
import { PlanningPage } from './app/planning/pages/PlanningPage';
import { SupplierTrucksPage } from './app/supplier/pages/SupplierTruck/SupplierTrucks';
import { CreateSupplierTruckPage } from './app/supplier/pages/SupplierTruck/CreateTruckPage';
import { UpdateSupplierTruckPage } from './app/supplier/pages/SupplierTruck/UpdateTruckPage';
import { OrderAuditLogsPage } from './app/order/pages/order/AuditLogs';
import { AuditLogPage } from './app/audit-log/pages/AuditLog';
import { CustomerAuditLogsPage } from './app/customer/pages/Customer/AuditLogs';
import { SupplierAuditLogsPage } from './app/supplier/pages/Supplier/AuditLogs';
import { LocationAuditLogsPage } from './app/location/pages/Location/AuditLogs';
import ForgotPasswordPage from './app/auth/pages/ForgotPassword';
import SetNewPasswordPage from './app/auth/pages/SetPassword';
import { CustomerUsersPage } from './app/customer/pages/Customer/CustomerUsers';
import { InviteCustomerUserPage } from './app/customer/pages/Customer/InviteCustomerUser';
import { CustomerUserInvitePage } from './app/customer/pages/CustomerUserInvitePage';
import { CustomerPortalHomePage } from './app/customer-portal/HomePage';
import { CustomerPortalWrapper } from './app/customer-portal/CustomerPortalWrapper';
import { CustomerPortalUsersPage } from './app/customer-portal/users/CustomerPortalUsers';
import { CustomerPortalInviteUserPage } from './app/customer-portal/users/CustomerPortalInviteUser';
import { CustomerPortalOrdersPage } from './app/customer-portal/orders/CustomerPortalOrders';
import { CustomerPortalOrderPage } from './app/customer-portal/orders/CustomerPortalOrder';
import { CustomerPortalMePage } from './app/customer-portal/users/CustomerPortalMe';
import { CustomerPortalUpdateCustomerDetailsPage } from './app/customer-portal/information/UpdateCustomerDetailsPage';
import { CustomerPortalContactsPage } from './app/customer-portal/contacts/CustomerPortalContactsPage';
import { CustomerPortalCreateContactPage } from './app/customer-portal/contacts/CustomerPortalCreateContact';
import { CustomerPortalUpdateContactPage } from './app/customer-portal/contacts/CustomerPortalUpdateContact';
import { InternalLoginPage } from './app/auth/pages/InternalLogin';
import { CustomerPortalLoginPage } from './app/auth/pages/CustomerPortalLogin';
import { Button } from './components/button/Button';
import { CustomerPortalQuotationsPage } from './app/customer-portal/quotations/Quotations';
import { CustomerPortalQuotationPage } from './app/customer-portal/quotations/Quotation';
import { CustomerPortalInvoicesPage } from './app/customer-portal/invoices/Invoices';
import { CustomerPortalInvoicePage } from './app/customer-portal/invoices/Invoice';
import { CustomerPortalPlaceOrderPage } from './app/customer-portal/orders/new/Create';
import { CustomerPortalLocationsPage } from './app/customer-portal/locations/CustomerPortalLocationsPage';
import { CustomerPortalLocationPage } from './app/customer-portal/locations/CustomerPortalLocationPage';
import { CustomerPortalCreateLocationPage } from './app/customer-portal/locations/CustomerPortalCreateLocationPage';
import { UserNotFoundPage } from './app/auth/pages/UserNotFoundPage';
import { OrderComplaintsPage } from './app/order/pages/order/OrderComplaints';
import { CreateManualSaleInvoicePage } from './app/sale/pages/sale/create-manual/CreateManualSaleInvoice';
import { AiAssistantPage } from './app/ai-assistant/pages/AiAssistantPage';
import { CreateMultiOrdersFromOrderPage } from './app/order/pages/order/CreateMultiFrom';
import { TransporeonSettingsPage } from './app/settings/pages/transporeon';
import CustomerPortalRequestAccountPage from './app/customer-portal/users/CustomerPortalRequestAccountPage';
import { CustomerPortalRequestQuotationPage } from './app/customer-portal/quotations/CreateRequest';
import { CustomerPortalQuotationRequestsPage } from './app/customer-portal/quotations/Requests';
import { CustomerPortalQuotationRequestPage } from './app/customer-portal/quotations/Request';
import { QuotationRequestsPage } from './app/quotation-request/pages/Requests';
import { QuotationRequestPage } from './app/quotation-request/pages/Request';
import { PaymentRemindersPage } from './app/payment-reminder/pages/PaymentRemindersPage';
import { CreatePaymentReminderPage } from './app/payment-reminder/pages/create/CreatePaymentReminderPage';
import { PaymentReminderPage } from './app/payment-reminder/pages/PaymentReminderPage';

const DashboardRoute = () => {
  return (
    <AuthProvider fallbackLogin="/internal/login">
      <WebsocketProvider>
        <NotificationsProvider>
          <MinimalGeneralSettingsProvider>
            <Dashboard>
              <Outlet />
            </Dashboard>
          </MinimalGeneralSettingsProvider>
        </NotificationsProvider>
      </WebsocketProvider>
    </AuthProvider>
  );
};

const AuthRedirector: React.FC<{ fallbackLogin: string }> = ({ fallbackLogin }) => {
  return (
    <AuthProvider fallbackLogin={fallbackLogin}>
      <AuthConsumer>
        {(auth) => {
          if (!auth?.me) {
            return <Navigate to={fallbackLogin} />;
          }

          if (auth.me.isInternal) {
            return <Navigate to="/internal" />;
          }

          if (auth.me.customer?.id) {
            return <Navigate to={`/customer-portal/${auth.me.customer.id}`} />;
          }

          return (
            <div>
              <div>Invalid user state</div>

              <div>
                <Button onTrigger={() => auth.logout()}>Logout</Button>
              </div>
            </div>
          );
        }}
      </AuthConsumer>
    </AuthProvider>
  );
};

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/internal">
        {/** Internal Login */}
        <Route path="login" element={<InternalLoginPage />} />

        <Route path="*" element={<DashboardRoute />}>
          <Route
            index
            element={
              <AuthConsumer>
                {(auth) => {
                  return <Navigate to={auth?.me.role === 'Admin' ? 'stats' : 'orders'} />;
                }}
              </AuthConsumer>
            }
          />
          <Route path="me" element={<MePage />} />
          <Route path="stats" element={<StatisticsPage />}>
            <Route index element={<Navigate to="overview" />} />
            <Route path="overview" element={<OrdersOverviewStats />} />
            <Route path="accounting" element={<AccountingStats />} />
          </Route>
          <Route path="notifications" element={<NotificationsPage />} />
          <Route path="suppliers">
            <Route index element={<SuppliersPage />} />
            <Route path="new" element={<CreateSupplierPage />} />
            <Route path=":supplierId" element={<SupplierPage />}>
              <Route path="general" element={<UpdateSupplierForm />} />
              <Route path="contacts">
                <Route index element={<SupplierContacts />} />
                <Route path="new" element={<CreateSupplierContactPage />} />
                <Route path=":supplierContactId" element={<UpdateSupplierContactPage />} />
              </Route>
              <Route path="trucks">
                <Route index element={<SupplierTrucksPage />} />
                <Route path="new" element={<CreateSupplierTruckPage />} />
                <Route path=":supplierTruckId" element={<UpdateSupplierTruckPage />} />
              </Route>
              <Route path="audit-logs" element={<SupplierAuditLogsPage />} />
            </Route>
          </Route>
          <Route path="customers">
            <Route index element={<CustomersPage />} />
            <Route path="new" element={<CreateCustomerPage />} />
            <Route path=":customerId" element={<CustomerPage />}>
              <Route path="general" element={<UpdateCustomerForm />} />
              <Route path="contacts">
                <Route index element={<CustomerContacts />} />
                <Route path="new" element={<CreateCustomerContactPage />} />
                <Route path=":customerContactId" element={<UpdateCustomerContactPage />} />
              </Route>
              <Route path="users">
                <Route index element={<CustomerUsersPage />} />
                <Route path="invite" element={<InviteCustomerUserPage />} />
              </Route>
              <Route path="audit-logs" element={<CustomerAuditLogsPage />} />
            </Route>
          </Route>
          <Route path="locations">
            <Route index element={<LocationsPage />} />
            <Route path="new" element={<CreateLocationPage />} />
            <Route path=":locationId" element={<LocationPage />}>
              <Route path="general" element={<UpdateLocationForm />} />
              <Route path="opening-hours" element={<LocationOpeningHours />} />
              <Route path="contacts">
                <Route index element={<LocationContacts />} />
                <Route path="new" element={<CreateLocationContactPage />} />
                <Route path=":locationContactId" element={<UpdateLocationContactPage />} />
              </Route>
              <Route path="audit-logs" element={<LocationAuditLogsPage />} />
            </Route>
          </Route>
          <Route path="orders">
            <Route index element={<OrdersPage />} />
            <Route path="new" element={<CreateOrderPage />} />
            <Route path="new-from-template" element={<CreateMultiOrdersFromOrderPage />} />
            <Route path="incoming">
              <Route index element={<IncomingOrdersPage />} />
              <Route path=":orderEmailId" element={<IncomingOrderPage />} />
            </Route>
            <Route path=":orderId" element={<OrderPage />}>
              <Route index element={<Navigate to="general" />} />
              <Route path="general" element={<UpdateOrder />} />
              <Route path="complaints" element={<OrderComplaintsPage />} />
              <Route path="lines">
                <Route index element={<OrderLinesPage />} />
                <Route path="new" element={<CreateOrderLinePage />} />
                <Route path=":orderLineId" element={<OrderLineDetails />}>
                  <Route path="contents">
                    <Route index element={<OrderLineContentsPage />} />
                    <Route path="new" element={<CreateOrderLineContentPage />} />
                    <Route path=":orderLineContentId" element={<UpdateOrderLineContentPage />} />
                  </Route>
                  <Route path="purchases">
                    <Route index element={<OrderPurchasesPage />} />
                    <Route path="new" element={<CreateOrderPurchasePage />} />
                    <Route path=":orderPurchaseId" element={<UpdateOrderPurchasePage />} />
                  </Route>
                  <Route path="sales">
                    <Route index element={<OrderLineSalesPage />} />
                    <Route path="new" element={<CreateOrderLineSalePage />} />
                    <Route path=":orderLineSaleId" element={<UpdateOrderLineSalePage />} />
                  </Route>
                </Route>
              </Route>
              <Route path="audit-logs" element={<OrderAuditLogsPage />} />
            </Route>
          </Route>
          <Route path="planning" element={<PlanningPage />} />
          <Route path="incoming-docs">
            <Route index element={<IncomingDocsPage />} />
            <Route path=":incomingDocId" element={<IncomingDocPage />} />
          </Route>
          <Route path="finance">
            <Route index element={<FinancePage />} />
            <Route path="sales">
              <Route index element={<SalesInvoicesPage />} />
              <Route path="new" element={<CreateInvoicePage />} />
              <Route path="new-manual" element={<CreateManualSaleInvoicePage />} />
              <Route path=":saleId" element={<InvoicePage />} />
            </Route>
            <Route path="quotations">
              <Route index element={<QuotationsPage />} />
              <Route path="new" element={<NewQuotationPage />} />
              <Route path=":quotationId" element={<QuotationPage />} />
            </Route>
            <Route path="quotation-requests">
              <Route index element={<QuotationRequestsPage />} />
              <Route path=":quotationRequestId" element={<QuotationRequestPage />} />
            </Route>
            <Route path="purchases">
              <Route index element={<PurchasesPage />} />
              <Route path="new" element={<CreatePurchasePage />} />
              <Route path=":purchaseId" element={<PurchasePage />} />
            </Route>
            <Route path="creditnotes">
              <Route index element={<CreditNotesPage />} />
              <Route path="new" element={<CreateCreditNotePage />} />
              <Route path=":creditNoteId" element={<CreditNotePage />} />
            </Route>
            <Route path="self-billing">
              <Route index element={<SelfBillingsPage />} />
              <Route path=":selfBillId" element={<SelfBillPage />} />
            </Route>
            <Route path="payment-reminder">
              <Route index element={<PaymentRemindersPage />} />
              <Route path="new" element={<CreatePaymentReminderPage />} />
              <Route path=":paymentReminderId" element={<PaymentReminderPage />} />
            </Route>
          </Route>
          <Route path="settings" element={<SettingsPage />}>
            <Route index element={<Navigate to="general" />} />
            <Route path="general" element={<GeneralSettingsPage />} />
            <Route path="order" element={<OrderSettingsPage />} />
            <Route path="invoice" element={<InvoiceSettingsPage />} />
            <Route path="email" element={<EmailSettingsPage />}>
              <Route path=":emailType" element={<EmailTypeSettingsPage />} />
            </Route>
            <Route path="document" element={<DocumentNoteSettingsPage />}>
              <Route path=":docType" element={<DocumentNoteTypeSettingsPage />} />
            </Route>
            <Route path="mailbox">
              <Route index element={<MailboxesSettingsPage />} />
              <Route path="configure" element={<ConfigureMailboxPage />} />
              <Route path=":mailboxId" element={<UpdateMailboxPage />} />
            </Route>
            <Route path="users">
              <Route index element={<UsersPage />} />
              <Route path="new" element={<CreateUserPage />} />
              <Route path=":userId" element={<UserPage />} />
            </Route>
            <Route path="transporeon" element={<TransporeonSettingsPage />} />
            <Route path="actions" element={<GeneralActionsPage />} />
          </Route>
          <Route path="audit-logs">
            <Route index element={<AuditLogsPage />} />
            <Route path=":auditLogId" element={<AuditLogPage />} />
          </Route>
          <Route path="ai-assistant" element={<AiAssistantPage />} />
          <Route path="*" element={<p>Pagina niet gevonden</p>} />
        </Route>
      </Route>

      <Route path="/customer-portal">
        <Route index element={<AuthRedirector fallbackLogin="/customer-portal/login" />} />

        {/** Customer Portal Login */}
        <Route path="login" element={<CustomerPortalLoginPage />} />

        <Route path=":customerId" element={<CustomerPortalWrapper />}>
          <Route index element={<CustomerPortalHomePage />} />
          <Route path="orders">
            <Route index element={<CustomerPortalOrdersPage />} />
            <Route path="new" element={<CustomerPortalPlaceOrderPage />} />
            <Route path=":orderId" element={<CustomerPortalOrderPage />} />
          </Route>
          <Route path="locations">
            <Route index element={<CustomerPortalLocationsPage />} />
            <Route path="new" element={<CustomerPortalCreateLocationPage />} />
            <Route path=":locationId" element={<CustomerPortalLocationPage />} />
          </Route>
          <Route path="quotations">
            <Route index element={<CustomerPortalQuotationsPage />} />
            <Route path="requests">
              <Route index element={<CustomerPortalQuotationRequestsPage />} />
              <Route path="new" element={<CustomerPortalRequestQuotationPage />} />
              <Route path=":quotationRequestId" element={<CustomerPortalQuotationRequestPage />} />
            </Route>
            <Route path=":quotationId" element={<CustomerPortalQuotationPage />} />
          </Route>
          <Route path="invoices">
            <Route index element={<CustomerPortalInvoicesPage />} />
            <Route path=":invoiceId" element={<CustomerPortalInvoicePage />} />
          </Route>
          <Route path="users">
            <Route index element={<CustomerPortalUsersPage />} />
            <Route path="invite" element={<CustomerPortalInviteUserPage />} />
          </Route>
          <Route path="me" element={<CustomerPortalMePage />} />
          <Route path="business-information" element={<CustomerPortalUpdateCustomerDetailsPage />} />
          <Route path="contacts">
            <Route index element={<CustomerPortalContactsPage />} />
            <Route path="new" element={<CustomerPortalCreateContactPage />} />
            <Route path=":customerContactId" element={<CustomerPortalUpdateContactPage />} />
          </Route>
          <Route path="*" element={<Navigate to="." />} />
        </Route>
      </Route>

      {/** Supplier routes */}
      <Route path="/confirm-order" element={<OrderConfirmationPage />} />
      <Route path="/refuse-order" element={<OrderRefusalPage />} />

      {/** General auth logic */}
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/set-new-password" element={<SetNewPasswordPage />} />
      <Route path="/customer-user-invite" element={<CustomerUserInvitePage />} />
      <Route path="/user-not-found" element={<UserNotFoundPage />} />
      <Route path="/request-customer-portal-account" element={<CustomerPortalRequestAccountPage />} />

      {/**
       * Later move this to navigate to /customer-portal/login as we don't wanna expose internals to the outside world
       **/}
      <Route path="*" element={<AuthRedirector fallbackLogin="/customer-portal/login" />} />
    </Routes>
  );
};
