import { Link, useParams } from 'react-router-dom';

import { PageHeader } from '../../../components/PageHeader';
import { invariant, nullthrows } from '../../../utils/invariant';
import { useQuotationRequestQuery } from '../../../generated/graphql';
import { DataField } from '../../../components/DataField';
import { formatDate } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { TrailerTypeIcon } from '../../order/pages/order/TrailerTypes';
import { LinkButton } from '../../../components/button/ButtonLink';

export const QuotationRequestPage = () => {
  const { quotationRequestId } = useParams<{ quotationRequestId: string }>();
  invariant(quotationRequestId);

  const [{ data }] = useQuotationRequestQuery({
    variables: {
      id: quotationRequestId,
    },
  });

  const quotation = nullthrows(data?.quotationRequest, 'Offerte aanvraag niet gevonden');
  const title = `Offerte aanvraag ${quotation.reference}`;

  return (
    <>
      <PageHeader title={title} />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: 'Offerte aanvragen',
                to: '..',
              },
              {
                name: title,
              },
            ]}
          />

          <div>
            <LinkButton to={`/internal/finance/quotations/new?quotation-req-id=${quotationRequestId}`}>
              Maak offerte aan
            </LinkButton>
          </div>
        </div>

        <div className="flex gap-4 mb-8">
          <DataField title="Type">{quotation.type}</DataField>
          <DataField title="Referentie">{quotation.reference}</DataField>
          <DataField title="Aangevraagd op">{formatDate(quotation.createdAt)}</DataField>
          <DataField title="Offerte">
            {quotation.quotationId ? (
              <Link to={`../../quotations/${quotation.quotationId}`}>{quotation.reference}</Link>
            ) : (
              'n/a'
            )}
          </DataField>
        </div>

        {quotation.notes.length > 0 && (
          <div className="mb-4">
            <DataField title="Notities">
              <span className="whitespace-pre-wrap">{quotation.notes}</span>
            </DataField>
          </div>
        )}

        <div>
          <div className="grid grid-cols-8 font-medium mb-2">
            <div className="col-span-3">Vertrek</div>
            <div className="col-span-3">Bestemming</div>
          </div>

          {quotation.lines.map((line) => {
            return (
              <div className="my-1" key={line.id}>
                <div className="grid grid-cols-8">
                  <div className="col-span-3">{`${line.departureCountry}${line.departurePostalCode} ${line.departureCity}`}</div>
                  <div className="col-span-3">{`${line.arrivalCountry}${line.arrivalPostalCode} ${line.arrivalCity}`}</div>
                </div>

                <div className="flex flex-wrap gap-2 mt-2">
                  {line.trailerTypes.map((trailerType) => {
                    return (
                      <div
                        className="bg-dark-05 rounded flex flex-col items-center justify-center px-2 py-1"
                        key={`${line.id}-${trailerType}`}
                      >
                        <TrailerTypeIcon type={trailerType} variant="small" />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
