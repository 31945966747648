import { useNavigate, useParams } from 'react-router-dom';
import { PlusIcon, TrashIcon } from 'lucide-react';
import toast from 'react-hot-toast';
import { FieldArray, Formik } from 'formik';
import { useMemo, useState } from 'react';
import * as Yup from 'yup';

import { PageHeader } from '../../../components/PageHeader';
import { invariant, nullthrows } from '../../../utils/invariant';
import {
  Country,
  QuotationType,
  useDeleteQuotationMutation,
  useGenerateQuotationPdfMutation,
  useGetQuotationQuery,
  useSendQuotationEmailMutation,
  useUpdateQuotationMutation,
} from '../../../generated/graphql';
import { formatNumber, parseNumberInput } from '../../../utils/number';
import { DataField } from '../../../components/DataField';
import { formatDate, formatInputDate } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { FileUploaderButton } from '../../document/components/FileUploaderButton';
import { ContactsDialog } from '../../../components/dialog/ContactsDialog';
import { getDisplayError } from '../../../utils/get-display-error';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';
import { SimpleComboboxField } from '../../../components/combobox/SimpleComboboxField';
import { InputField } from '../../../components/input/InputField';
import { TextAreaField } from '../../../components/textarea/TextAreaField';
import { Button } from '../../../components/button/Button';
import { TrailerTypes } from '../../order/pages/order/TrailerTypes';
import { ILineState, IQuotationLineValues, initialLineValues } from './types';
import { QUOTATION_TYPE_OPTIONS } from '../constants';
import { createRoute } from '../../../utils/mapbox-api.client';
import { serializeCoordinates } from '../../../utils/mapbox-api';
import { ISimpleSelectItem } from '../../../components/select/SimpleSelect';
import { COUNTRY_VALUES } from '../../../utils/address';
import { AutocompletePostalcode } from '../../location/components/AutocompletePostalcode';

const createQuotationLineSchema = Yup.object().shape({
  departurePostalCode: Yup.mixed().nullable().required('Vereist'),
  arrivalPostalCode: Yup.mixed().nullable().required('Vereist'),
  distance: Yup.string().required('Vereist'),
  price: Yup.string().required('Vereist'),
  trailerTypes: Yup.array().required('Vereist'),
});

const createQuotationSchema = Yup.object().shape({
  reference: Yup.string().required('Vereist'),
  notes: Yup.string(),
  quotationDate: Yup.string().required('Vereist'),
  expiresAt: Yup.string().required('Vereist'),
  lines: Yup.array(createQuotationLineSchema).required('Vereist'),
});

interface IQuotationValues {
  type: ISimpleSelectItem;
  reference: string;
  quotationDate: string;
  expiresAt: string;
  notes: string;
  lines: IQuotationLineValues[];
}

export const QuotationPage = () => {
  const navigate = useNavigate();
  const { quotationId } = useParams<{ quotationId: string }>();
  invariant(quotationId);

  const [{ data }] = useGetQuotationQuery({
    variables: {
      id: quotationId,
    },
  });
  const [_generateQuotationResult, generateQuotationPdf] = useGenerateQuotationPdfMutation();
  const [_sendQuotationEmailResult, sendQuotationEmail] = useSendQuotationEmailMutation();
  const [_deleteQuotationRes, deleteQuotation] = useDeleteQuotationMutation();
  const [_updateQuotationRes, updateQuotation] = useUpdateQuotationMutation();

  const quotation = nullthrows(data?.quotation, 'Quotation not found');
  const title = `Offerte ${quotation.reference}`;
  const totalAmount = quotation.lines.reduce((acc, line) => acc + line.price, 0);

  const initialValues: IQuotationValues = useMemo(() => {
    return {
      type: QUOTATION_TYPE_OPTIONS.find((v) => v.key === quotation.type)!,
      reference: quotation.reference,
      quotationDate: formatInputDate(quotation.quotationDate),
      expiresAt: formatInputDate(quotation.expiresAt),
      notes: quotation.notes,
      lines: quotation.lines.map((l) => {
        return {
          departurePostalCode: l.departurePostalCode,
          departureCountry: COUNTRY_VALUES.find((v) => v.key === l.departureCountry)!,
          departureCity: l.departureCity,
          arrivalPostalCode: l.arrivalPostalCode,
          arrivalCountry: COUNTRY_VALUES.find((v) => v.key === l.arrivalCountry)!,
          arrivalCity: l.arrivalCity,
          distance: formatNumber(l.distance, 2),
          price: formatNumber(l.price, 2),
          trailerTypes: l.trailerTypes,
        };
      }),
    };
  }, [quotation]);

  const [_lines, setLines] = useState<ILineState[]>(() => {
    return quotation.lines.map((v) => {
      return {
        departure: undefined,
        arrival: undefined,
        fetchKey: Date.now().toString(),
      };
    });
  });

  return (
    <>
      <PageHeader title={title} />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: 'Offertes',
                to: '/internal/finance/quotations',
              },
              {
                name: title,
              },
            ]}
          />

          <div className="flex gap-2">
            <FileUploaderButton
              buttonText="OFR"
              title="Offerte"
              file={quotation.document}
              generateDocument={async () => {
                const res = await generateQuotationPdf({
                  quotationId,
                });
                if (res.error) {
                  throw res.error;
                }
              }}
              disableUpload
            />

            <ContactsDialog
              triggerText="Verstuur offerte"
              triggerColor="primary"
              title="Verstuur offerte"
              submitText="Verstuur"
              description="Ben je zeker dat je de offerte wilt versturen?"
              contacts={
                quotation.customer?.contacts.map((c) => {
                  return {
                    id: '' + c.id,
                    name: c.name,
                    email: c.email,
                  };
                }) ?? []
              }
              initialValue={
                quotation.customer?.contacts.filter((c) => c.shouldReceiveQuotations).map((v) => '' + v.id) ?? []
              }
              onSubmit={async (contacts) => {
                try {
                  const res = await sendQuotationEmail({
                    quotationId: quotation.id,
                    contacts: contacts.map((c) => +c),
                  });
                  if (res.error) {
                    throw res.error;
                  }
                  toast.success('Offerte verstuurd');
                } catch (err: any) {
                  toast.error('Kon offerte niet versturen: ' + getDisplayError(err));
                  throw err;
                }
              }}
            />

            <ConfirmDialog
              triggerText={<TrashIcon className="button-icon" />}
              title="Offerte verwijderen?"
              submitText="Verwijder offerte"
              description={<div>Ben je zeker dat je de offerte wilt verwijderen?</div>}
              onSubmit={async () => {
                try {
                  const result = await deleteQuotation({
                    quotationId: quotation.id,
                  });
                  if (result.error) {
                    throw result.error;
                  }
                  navigate('..');
                  toast.success('Offerte verwijderd');
                } catch (err) {
                  console.error(err);
                  toast.error('Kon offerte niet verwijderen: ' + getDisplayError(err));
                }
              }}
            />
          </div>
        </div>

        <div className="flex gap-4 mb-8">
          <DataField title="Klant">{quotation.customer.name}</DataField>
          <DataField title="Type">{quotation.type}</DataField>
          <DataField title="Referentie">{quotation.reference}</DataField>
          <DataField title="Offerte Datum">{formatDate(quotation.quotationDate)}</DataField>
          <DataField title="Vervalt op">{formatDate(quotation.expiresAt)}</DataField>
          <DataField title="Aangemaakt op">{formatDate(quotation.createdAt)}</DataField>
          <DataField title="Totaal">
            <span className="whitespace-nowrap">{`€ ${formatNumber(totalAmount, 2, {
              decimalSeperator: ',',
            })}`}</span>
          </DataField>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={createQuotationSchema}
          onSubmit={async (values) => {
            try {
              const { type, reference, notes, quotationDate, expiresAt, lines } = values;
              const result = await updateQuotation({
                quotationId: quotation.id,
                data: {
                  type: type.key as QuotationType,
                  reference,
                  notes,
                  quotationDate,
                  expiresAt,
                  lines: lines.map((l) => {
                    return {
                      departurePostalCode: l.departurePostalCode,
                      departureCountry: l.departureCountry.key as Country,
                      departureCity: l.departureCity,
                      arrivalPostalCode: l.arrivalPostalCode,
                      arrivalCountry: l.arrivalCountry.key as Country,
                      arrivalCity: l.arrivalCity,
                      distance: parseNumberInput(l.distance, 2),
                      price: parseNumberInput(l.price, 2),
                      trailerTypes: l.trailerTypes,
                    };
                  }),
                },
              });
              if (result.error) {
                throw result.error;
              }
              toast.success('Offerte aangepast');
            } catch (err: any) {
              toast.error('Kon offerte niet aanpassen: ' + getDisplayError(err));
            }
          }}
        >
          {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <SimpleComboboxField items={QUOTATION_TYPE_OPTIONS} name="type" labelText="Offerte type" />

              <InputField type="text" labelText="Referentie klant" name="reference" isDisabled={isSubmitting} />

              <div className="grid grid-cols-2 gap-4">
                <InputField labelText="Offertedatum" type="date" name="quotationDate" />
                <InputField labelText="Vervalt op" type="date" name="expiresAt" />
              </div>

              <TextAreaField labelText="Notities" name="notes" isDisabled={isSubmitting} spellCheck={true} />

              <FieldArray
                name="lines"
                render={(arrayHelpers) => (
                  <div className="my-4">
                    <div className="flex justify-between items-end">
                      <h2 className="heading-two mb-2">Lijnen</h2>
                      <Button
                        onTrigger={() => {
                          const itemToAdd = { ...initialLineValues };
                          arrayHelpers.push(itemToAdd);
                          setLines((prev) => {
                            return [
                              ...prev,
                              {
                                departure: undefined,
                                arrival: undefined,
                                fetchKey: Date.now().toString(),
                              },
                            ];
                          });
                        }}
                        iconLeft={<PlusIcon className="button-icon" />}
                      >
                        Voeg lijn toe
                      </Button>
                    </div>

                    {values['lines'].length > 0 ? (
                      <div>
                        {values['lines'].map((_line, idx) => {
                          const fetchRoute = async (state: ILineState) => {
                            if (!state.departure || !state.arrival) {
                              return;
                            }

                            const fetchKey = `${serializeCoordinates(state.departure)}-${serializeCoordinates(
                              state.arrival,
                            )}`;
                            if (state.fetchKey === fetchKey) {
                              return;
                            }

                            setLines((prev) => {
                              prev[idx].fetchKey = fetchKey;
                              return prev;
                            });

                            const response = await createRoute(state.departure, state.arrival);
                            if (response) {
                              setLines((prev) => {
                                prev.forEach((l, idx) => {
                                  if (l.fetchKey === fetchKey) {
                                    setFieldValue(`lines[${idx}].distance`, (response.distance / 1000).toFixed(2));
                                  }
                                });

                                return prev;
                              });
                            }
                          };

                          return (
                            <div className="flex flex-col mt-4" key={`quotation-line-${idx}`}>
                              <div className="flex w-full gap-4">
                                <div className="grid quotation-line-form-columns gap-4 w-full">
                                  <div>
                                    <div className="font-medium">Van</div>
                                    <div>
                                      <AutocompletePostalcode
                                        postalCodeName={`lines[${idx}].departurePostalCode`}
                                        countryName={`lines[${idx}].departureCountry`}
                                        cityName={`lines[${idx}].departureCity`}
                                        onAutocomplete={(_postalCode, _city, longitude, latitude) => {
                                          setLines((prev) => {
                                            prev[idx].departure = [longitude, latitude];
                                            fetchRoute(prev[idx]).catch(console.error);
                                            return [...prev];
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <div className="font-medium">Naar</div>
                                    <div>
                                      <AutocompletePostalcode
                                        postalCodeName={`lines[${idx}].arrivalPostalCode`}
                                        countryName={`lines[${idx}].arrivalCountry`}
                                        cityName={`lines[${idx}].arrivalCity`}
                                        onAutocomplete={(_postalCode, _city, longitude, latitude) => {
                                          setLines((prev) => {
                                            prev[idx].arrival = [longitude, latitude];
                                            fetchRoute(prev[idx]).catch(console.error);
                                            return [...prev];
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="2xl:pt-6">
                                    <InputField
                                      labelText="Afstand (in km)"
                                      type="number"
                                      name={`lines[${idx}].distance`}
                                      isDisabled={isSubmitting}
                                    />
                                  </div>
                                  <div className="2xl:pt-6">
                                    <InputField
                                      labelText="Prijs"
                                      type="number"
                                      name={`lines[${idx}].price`}
                                      isDisabled={isSubmitting}
                                      step="0.01"
                                    />
                                  </div>
                                </div>
                                <div className="flex items-center pt-6">
                                  <Button
                                    onTrigger={() => {
                                      arrayHelpers.remove(idx);
                                      setLines((prev) => {
                                        return prev.filter((_, i) => i !== idx);
                                      });
                                    }}
                                  >
                                    <TrashIcon className="button-icon" />
                                  </Button>
                                </div>
                              </div>
                              <div>
                                <div className="max-w-6xl">
                                  <TrailerTypes
                                    value={values.lines[idx].trailerTypes}
                                    onChange={(newTrailerTypes) => {
                                      setFieldValue(`lines[${idx}].trailerTypes`, newTrailerTypes);
                                    }}
                                    isMultiSelect={true}
                                    isRequired={true}
                                    variant="small"
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div>Geen lijnen</div>
                    )}
                  </div>
                )}
              />

              <div className="mt-8">
                <Button
                  type="submit"
                  color="primary"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  iconLeft={<PlusIcon className="button-icon" />}
                >
                  Pas offerte aan
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};
