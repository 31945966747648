import { SearchIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { ITableHeader, Table } from '../../../components/table/Table';
import {
  QuotationRequestsDocument,
  QuotationRequestsQuery,
  QuotationRequestsQueryVariables,
} from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { formatDate } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';

export const QuotationRequestsPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<QuotationRequestsQuery, QuotationRequestsQueryVariables>(
          QuotationRequestsDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.quotationRequests ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 50,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  const tableHeaders: ITableHeader[] = [
    {
      id: 'customer',
      name: 'Klant',
    },
    {
      id: 'reference',
      name: 'Referentie',
    },
    {
      id: 'quote',
      name: 'Offerte',
    },
    {
      id: 'date',
      name: 'Aangevraagd op',
    },
  ];

  const title = 'Offerte aanvragen';
  return (
    <>
      <PageHeader title={title} />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: title,
              },
            ]}
          />

          <div className="flex gap-4">
            <div className="flex w-96">
              <Input
                type="text"
                placeholder="Zoek naar een aanvraag..."
                value={searchValue}
                onChange={setSearchValue}
                iconLeft={<SearchIcon className="input-icon" />}
              />
            </div>
          </div>
        </div>

        <Table
          idKey="id"
          headers={tableHeaders}
          data={page.data}
          mapData={(request) => {
            return [
              <Link to={`/internal/customers/${request.customer.id}/general`} className="link-text">
                {request.customer.name}
              </Link>,
              <Link to={`${request.id}`} className="link-text">
                {request.reference ?? 'DRAFT'}
              </Link>,
              request.quotationId ? (
                <Link to={`../quotations/${request.quotationId}`}>{request.reference}</Link>
              ) : (
                'n/a'
              ),
              formatDate(request.createdAt),
            ];
          }}
        />

        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};
