import { Breadcrumb } from 'components/Breadcrumb';
import { PageHeader } from 'components/PageHeader';
import { PaymentReminderInvoiceSelect } from './InvoiceSelect';

export const CreatePaymentReminderPage = () => {
  return (
    <div>
      <PageHeader title="Nieuwe betaalherinnering" />

      <div className="page-heading">
        <Breadcrumb
          items={[
            {
              name: 'Betaalherinneringen',
              to: '/internal/finance/payment-reminder',
            },
            {
              name: 'Nieuwe betaal herinnering',
              to: '/internal/finance/payment-reminder/new',
            },
          ]}
        />
      </div>

      <PaymentReminderInvoiceSelect />
    </div>
  );
};
