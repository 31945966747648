import { useId } from 'react';
import classNames from '@utils/classnames';
import { CheckIcon } from 'lucide-react';

import { TrailerType } from 'generated/graphql';
import { Checkbox } from 'components/checkbox/Checkbox';
import { nullthrows } from '../../../../utils/invariant';

const TRAILER_TYPE_ITEMS = [
  {
    title: 'Tautliner',
    value: TrailerType.Tautliner,
    icon: '/static/illustrations/TD-Tautliner-v2.svg',
  },
  {
    title: 'Box Trailer',
    value: TrailerType.BoxTrailer,
    icon: '/static/illustrations/TD-Box-v2.svg',
  },
  {
    title: 'Mega Trailer',
    value: TrailerType.Mega,
    icon: '/static/illustrations/TD-Mega-v2.svg',
  },
  {
    title: 'Road Train',
    value: TrailerType.RoadTrain,
    icon: '/static/illustrations/TD-Road-train-v2.svg',
  },
  {
    title: 'Flat bed',
    value: TrailerType.FlatBed,
    icon: '/static/illustrations/TD-Flat-v2.svg',
  },
  {
    title: 'Koeltrailer',
    value: TrailerType.Refrigerated,
    icon: '/static/illustrations/TD-Refrigerated-v2.svg',
  },
  {
    title: 'Tautliner / Joloda',
    value: TrailerType.Joloda,
    icon: '/static/illustrations/TD-Tautliner-v2.svg',
  },
];

export interface ITrailerTypeIconProps {
  type: TrailerType;
  variant?: 'regular' | 'small';
}

export const TrailerTypeIcon: React.FC<ITrailerTypeIconProps> = (props) => {
  const { type, variant = 'regular' } = props;

  const value = nullthrows(
    TRAILER_TYPE_ITEMS.find((v) => v.value === type),
    'Trailer type not found',
  );
  return (
    <div className="flex flex-col items-center">
      <img
        src={value.icon}
        alt={value.title}
        className={classNames({
          'w-24 h-24': variant === 'regular',
          'w-12 h-14': variant === 'small',
        })}
      />
      <div
        className={classNames('my-2 font-medium', {
          'text-md': variant === 'regular',
          'text-sm': variant === 'small',
        })}
      >
        {value.title}
      </div>
    </div>
  );
};

export interface ITrailerTypesProps {
  value: TrailerType[];
  onChange: (newValues: TrailerType[]) => void;
  isDisabled?: boolean;
  isMultiSelect?: boolean;
  isRequired?: boolean;
  variant?: 'regular' | 'small';
}

export const TrailerTypes: React.FC<ITrailerTypesProps> = (props) => {
  const { value, onChange, isDisabled, isMultiSelect, variant = 'regular', isRequired } = props;
  const id = useId();

  return (
    <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-x-4 gap-y-8">
      {TRAILER_TYPE_ITEMS.map((v) => {
        const key = id + v.value;
        const isChecked = value.includes(v.value);

        return (
          <label
            id={key}
            key={key}
            className={classNames(
              'flex flex-col items-center cursor-pointer border rounded-md hover:bg-dark-05 p-4 relative',
              {
                'border-dark-05': !isChecked,
                'border-orange-00': isChecked,
                'p-4': variant === 'regular',
                'p-2': variant === 'small',
              },
            )}
          >
            {isChecked && <CheckIcon className="absolute right-0 top-0 w-6 h-6 mr-2 mt-2 text-orange-00" />}
            <TrailerTypeIcon type={v.value} variant={variant} />
            <Checkbox
              className="sr-only"
              isChecked={isChecked}
              isDisabled={isDisabled}
              onChange={(newIsChecked) => {
                if (isDisabled) return;

                if (newIsChecked) {
                  if (isMultiSelect) {
                    onChange([...new Set([...value, v.value])]);
                  } else {
                    onChange([v.value]);
                  }
                } else {
                  if (isRequired && value.length <= 1) {
                    return;
                  }

                  onChange(value.filter((item) => item !== v.value));
                }
              }}
            />
          </label>
        );
      })}
    </div>
  );
};
